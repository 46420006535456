<template>
  <div class="grid">
    <div class="pg-header">
      <h2>Lagerplatser</h2>
    </div>
    <div id="alertBox" role="alert">
      <transition-group name="fade">
        <div v-show="showSuccessAlertBox" key="ok" class="alert alert-success">
          <strong>{{ message }}</strong>
        </div>
        <div v-show="showDangerAlertBox" key="nok" class="alert alert-danger">
          <strong>{{ message }}</strong>
        </div>
      </transition-group>
    </div>
    <div class="pg-table">
      <div v-if="errorMessage === '' && !loading">
        <div class="pg-table-toolbar">
          <div class="pg-search-input">
            <input v-model="search" type="text" class="searchInput" placeholder="Sök" />
          </div>
          <div class="pg-add-button">
            <button class="btn-primary" @click="addStorageLocation">Lägg till</button>
          </div>
        </div>

        <table id="tblStorageLocations" class="content-table content-table-striped">
          <thead>
            <tr>
              <th>Lagerplats</th>
              <th>Beskrivning</th>
            </tr>
          </thead>
          <tbody v-if="!loading && myStorageLocations.length > 0">
            <tr v-for="storageLocation in filtredStorageLocations" :key="storageLocation.storage_location_id" class="clickable" @click="editStorageLocation(storageLocation.storage_location_id)">
              <td>{{ storageLocation.storage_location_name }}</td>
              <td>{{ storageLocation.storage_location_description }}</td>
            </tr>
          </tbody>
          <div v-if="errorMessage === '' && !loading && myStorageLocations.length === 0" class="pg-center">
            <p>Hittar inga lagerplatser i databasen</p>
          </div>
        </table>
      </div>
      <div v-if="loading && errorMessage === ''" class="pg-center">
        <Spinner></Spinner>
      </div>
      <div v-if="errorMessage !== ''" class="pg-center">
        <p class="errorMessageText">{{ errorMessage }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Spinner from '@/components/utils/Spinner';
export default {
  name: 'StorageLocations',
  components: {
    Spinner
  },
  props: {
    addUpdateOK: {
      type: Boolean,
      default: false
    },
    hasAddUpdate: {
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      errorMessage: '',
      myStorageLocations: [],
      search: '',
      message: '',
      showSuccessAlertBox: false,
      showDangerAlertBox: false
    };
  },
  computed: {
    filtredStorageLocations() {
      const trimmedSearch = this.search.toLowerCase().trim();
      return this.myStorageLocations.filter((storageLocation) => {
        return storageLocation.storage_location_name.toLowerCase().match(trimmedSearch) || storageLocation.storage_location_description.toLowerCase().match(trimmedSearch);
      });
    }
  },
  async mounted() {
    this.loading = true;
    await this.fetchMyStorageLocations();
    this.loading = false;
  },
  created() {
    if (this.addUpdateOK && this.hasAddUpdate) {
      if (this.action == 'SAVE') {
        this.message = 'Lagerplatsen är nu sparad';
      }
      if (this.action == 'DELETE') {
        this.message = 'Lagerplatsen är nu raderad';
      }
      setTimeout(() => (this.showSuccessAlertBox = true), 0);
      setTimeout(() => (this.showSuccessAlertBox = false), 3000);
    }
    if (!this.addUpdateOK && this.hasAddUpdate) {
      if (this.action == 'SAVE') {
        this.message = 'Lagerplatsen kunde inte sparas';
      }
      if (this.action == 'DELETE') {
        this.message = 'Lagerplatsen kunde inte raderas. Lagerplatsen används i lagret.';
      }
      setTimeout(() => (this.showDangerAlertBox = true), 0);
      setTimeout(() => (this.showDangerAlertBox = false), 5000);
    }
  },
  updated() {},
  beforeUpdate() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    ...mapActions({
      fetchStorageLocations: 'storage-locations/fetchStorageLocations'
    }),
    editStorageLocation: function (storage_location_id) {
      this.$router.replace({
        name: 'StorageLocation',
        params: {
          storageLocationId: storage_location_id
        }
      });
    },
    addStorageLocation() {
      this.$router.replace({
        name: 'StorageLocation',
        params: {
          storageLocationId: 0
        }
      });
    },
    async fetchMyStorageLocations() {
      this.errorMessage = '';
      this.myStorageLocations = [];

      try {
        this.myStorageLocations = await this.fetchStorageLocations();
      } catch (error) {
        // TODO : FIX ERROR LOG
        if (error.response) {
          if (error.response.status === 401) {
            try {
              await this.$store.dispatch('logout');
            } catch (err) {
              //   console.error(err);
              // TODO : FIX ERROR LOG
            } finally {
              this.$router.replace({
                name: 'Login'
              });
            }
          } else {
            this.errorMessage = 'Kunde inte hämta lagerplatser';
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
